<script>
import uploadcare from 'uploadcare-widget'
import ui from '/~/core/ui'
import BaseCard from '/~/components/base/card/base-card.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import { useUser } from '/~/composables/user'

export default {
  name: 'image-drop',
  components: {
    BaseIcon,
    BaseImage,
    BaseCard,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    crop: {
      type: String,
      default: '600x400',
    },
    fileName: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'menu/camera-plus',
    },
    iconSize: {
      type: [String, Number],
      default: 'xl',
    },
  },
  setup() {
    const { fetchUploadSecureKey } = useUser()

    return {
      fetchUploadSecureKey,
      ui,
    }
  },
  data() {
    return {
      settings: {
        imagesOnly: true,
        crop: this.crop,
      },
      processing: false,
      dragging: false,
      key: null,
    }
  },
  computed: {
    isMobile() {
      return ui.mobile
    },
  },
  methods: {
    onDragOver(event) {
      event.stopPropagation()
      event.preventDefault()
      event.dataTransfer.dropEffect = 'copy'
    },
    onDrop(event) {
      event.stopPropagation() // stops some browsers from redirecting
      event.preventDefault()

      this.dragging = false

      const files = uploadcare.filesFrom(
        'object',
        event.dataTransfer.files,
        this.settings
      )

      this.onUpload(files)
    },
    async onUpload(files = null) {
      this.processing = true

      this.key = await this.fetchUploadSecureKey()

      const dialog = uploadcare.openDialog(files, {
        previewUrlCallback: function (originalUrl, fileInfo) {
          return `/2.0/me/uploads/proxy/${fileInfo.uuid}`
        },
        publicKey: this.key.public_key,
        secureSignature: this.key.signature,
        secureExpire: this.key.expire,
        tabs: 'file camera',
        cameraMirrorDefault: false,
        ...this.settings,
      })

      dialog.always(() => {
        this.processing = false
      })

      dialog.done((filePromise) => {
        filePromise.done((file) => {
          this.$emit('upload', file)
        })
      })
    },
    removeImage() {
      if (this.disabled) return

      this.$emit('upload', null)
    },
  },
}
</script>

<template>
  <base-card
    class="relative w-full rounded-xl"
    :disabled="disabled || processing"
    tabindex="0"
    role="button"
    @click="onUpload"
    @keyup.space="onUpload"
    @dragenter="dragging = true"
    @dragleave="dragging = false"
    @dragover="onDragOver"
    @drop="onDrop"
  >
    <div
      class="flex h-full cursor-pointer flex-col items-center justify-center overflow-hidden rounded-xl border-2 bg-light text-eonx-neutral-600 hover:border-dashed hover:border-primary hover:text-primary"
      :class="[
        dragging && 'border-dashed border-primary',
        disabled &&
          'cursor-not-allowed hover:border-disabled hover:text-eonx-neutral-600',
      ]"
    >
      <base-image
        v-if="image"
        :loading="processing"
        :src="image"
        :rounded="false"
      />
      <template v-else>
        <base-icon :svg="icon" :size="iconSize" class="pointer-events-none" />
        <div class="mt-2.5 text-center text-sm font-bold text-default">
          {{ label }}
        </div>
      </template>
    </div>
    <div
      v-if="image && removable"
      class="absolute right-0 top-0 -mr-2.5 -mt-2.5 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border-4 border-white bg-fg-error"
      @click.stop="removeImage"
    >
      <base-icon svg="plain/trash" size="sm" />
    </div>
  </base-card>
</template>
